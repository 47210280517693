import React from 'react'
import { Table } from 'antd'

const TableModule = () => {
    const columns = [
        {
            title: 'RealColibri Premium (RCP)',
            dataIndex: 'column1',
            key: 'column1',
        },
        {
            title: 'Number of RCP packages*',
            dataIndex: 'column2',
            key: 'column2',
        },
        {
            title: 'Price',
            dataIndex: 'column3',
            key: 'column3',
        },
        {
            title: 'Returns from 10% per month',
            dataIndex: 'column4',
            key: 'column4',
        },
    ]

    const data = [
        {
            key: '1',
            column1: 'RCP-10000',
            column2: '100',
            column3: '10,000 USDT (in TRX equiv.)',
            column4: 'Min 1,000 USDT',
        },
        {
            key: '2',
            column1: 'RCP-25000',
            column2: '40',
            column3: '25,000 USDT (in TRX equiv.)',
            column4: 'Min 2,500 USDT',
        },
        {
            key: '3',
            column1: 'RCP-50000',
            column2: '20',
            column3: '50,000 USDT (in TRX equiv.)',
            column4: 'Min 5,000 USDT',
        },
        {
            key: '4',
            column1: 'RCP-100000',
            column2: '10',
            column3: '100,000 USDT (in TRX equiv.)',
            column4: 'Min 10,000 USDT',
        },
        {
            key: '5',
            column1: 'RCP-250000',
            column2: '4',
            column3: '250,000 USDT (in TRX equiv.)',
            column4: 'Min 25,000 USDT',
        },
        {
            key: '6',
            column1: 'RCP-500000',
            column2: '2',
            column3: '500,000 USDT (in TRX equiv.)',
            column4: 'Min 50,000 USDT',
        },
        {
            key: '7',
            column1: 'RCP-1000000',
            column2: '1',
            column3: '1,000,000 USDT (in TRX equiv.)',
            column4: 'Min 100,000 USDT',
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            size={'small'}
            className="table"
            bordered
        />
    )
}

export default TableModule
