const translations = {
    en: {
        cryptoMarkets: 'CRYPTO MARKETS',
        ourFeatures: 'Our features:',
        feature1:
            'Any Binance customer, with a newly opened or existing account, can quickly and easily connect their trading account to RealColibri',
        feature2:
            'All profits remain in your trading account. We charge our commission for the use of the algorithm in our RCBC utility token.',
        feature3:
            'Only you control your trading account and the operation of the algorithm.',
        feature4:
            'Trading parameters are set to minimize risks for stable and safe trading.',
        chooseExchange: 'Choose your exchange',
        requirementsTitle: 'Requirements to get started:',
        requirement1: 'RealColibri account',
        requirement2: 'TronLink wallet',
        requirement3: 'Exchange account',
        requirement4: 'Minimum deposit to start automatic trading: 10,000 USDT',
        automateTitle: 'How to automate your trading:',
        automateStep1: 'Register at RealColibri',
        automateStep2:
            'Open an account at the exchange (<a href="https://www.binance.com/en/register?ref=39094470" target="_blank" rel="noreferrer" className={s.guideLink}>Binance</a>)',
        automateStep3:
            'Fund your trading account at the exchange and transfer funds to the futures wallet',
        automateStep4:
            'Create API keys in the account at the exchange and connect them in the RealColibri account',
        automateStep5:
            'Purchase RCBC and top up the commission deposit in the RealColibri account',
        automateStep6:
            'On the RealColibri automated trading page (go to Trade > Crypto > Binance in the top menu), select a symbol and press start',
        exchangeTitle: 'EXCHANGE TRX FOR RCBC',
        exchangeStep1: 'Create a TronLink Wallet',
        exchangeStep2: 'Transfer TRX to it',
        exchangeStep3: 'Exchange TRX for RCBC using this form',
        exchangeStep4:
            'Top up the commission deposit in your RealColibri account',
        exchangeInfo:
            'Exchange TRX for RCBC to pay a commission for using the algorithm.',
        refreshInfo:
            'Refresh the page after installation. You will be prompted to connect your wallet to the website.',
        contactUs:
            'Feel free to reach us if you have any questions or need additional information:',
        contactEmail: 'mail@realcolibri.com',
    },
    hy: {
        cryptoMarkets: 'ԿՐԻՊՏՈ ՇՈՒԿԱՆԵՐ',
        ourFeatures: 'Մեր առանձնահատկությունները՝',
        feature1:
            'Binance-ի ցանկացած հաճախորդ, թե նոր բացված, թե գործող հաշիվ ունեցող, կարող է արագ և հեշտությամբ միացնել իր առևտրային հաշիվը RealColibri-ին',
        feature2:
            'Բոլոր շահույթները մնում են ձեր առևտրային հաշվում: Մենք վերցնում ենք մեր միջնորդավճարը ալգորիթմի օգտագործման համար մեր RCBC օգտակար թոքենով:',
        feature3:
            'Միայն դուք եք կառավարում ձեր առևտրային հաշիվը և ալգորիթմի աշխատանքը:',
        feature4:
            'Առևտրային պարամետրերը սահմանված են ռիսկերը նվազագույնի հասցնելու համար՝ կայուն և անվտանգ առևտրի համար:',
        chooseExchange: 'Ընտրեք ձեր բորսան',
        requirementsTitle: 'Պահանջներ՝ սկսելու համար՝',
        requirement1: 'RealColibri-ի հաշիվ',
        requirement2: 'TronLink դրամապանակ',
        requirement3: 'Բորսայի հաշիվ',
        requirement4:
            'Մինիմալ դեպոզիտ՝ ավտոմատ առևտուր սկսելու համար՝ 10,000 USDT',
        automateTitle: 'Ինչպես ավտոմատացնել ձեր առևտուրը՝',
        automateStep1: 'Գրանցվեք RealColibri-ում',
        automateStep2:
            'Բացեք հաշիվ բորսայում (<a href="https://www.binance.com/en/register?ref=39094470" target="_blank" rel="noreferrer" className={s.guideLink}>Binance</a>)',
        automateStep3:
            'Լրացրեք ձեր առևտրային հաշիվը բորսայում և փոխանցեք միջոցները ֆյուչերսային դրամապանակ',
        automateStep4:
            'Ստեղծեք API բանալիներ բորսայի հաշվում և միացրեք դրանք RealColibri-ի հաշվին',
        automateStep5:
            'Գնեք RCBC և լրացրեք միջնորդավճարի դեպոզիտը RealColibri-ի հաշվին',
        automateStep6:
            'RealColibri-ի ավտոմատ առևտրի էջում (անցեք Առևտուր > Կրիպտո > Binance վերևի մենյուում), ընտրեք սիմվոլը և սեղմեք սկսեք',
        exchangeTitle: 'ՓՈԽԱՐԿԵՔ TRX RCBC-Ի ԴԵՄ',
        exchangeStep1: 'Ստեղծեք TronLink դրամապանակ',
        exchangeStep2: 'Փոխանցեք TRX դրան',
        exchangeStep3: 'Փոխարկեք TRX-ը RCBC-ի դեմ՝ օգտագործելով այս ձևը',
        exchangeStep4:
            'Լրացրեք միջնորդավճարի դեպոզիտը ձեր RealColibri-ի հաշվին',
        exchangeInfo:
            'Փոխարկեք TRX-ը RCBC-ի դեմ՝ ալգորիթմի օգտագործման միջնորդավճար վճարելու համար:',
        refreshInfo:
            'Թարմացրեք էջը տեղադրումից հետո: Ձեզ կառաջարկվի միացնել ձեր դրամապանակը կայքին:',
        contactUs:
            'Խնդրում ենք կապվել մեզ հետ, եթե ունեք հարցեր կամ լրացուցիչ տեղեկություններ անհրաժեշտ են՝',
        contactEmail: 'mail@realcolibri.com',
    },
    ru: {
        cryptoMarkets: 'КРИПТО РЫНКИ',
        ourFeatures: 'Наши особенности:',
        feature1:
            'Любой клиент Binance, с недавно открытым или существующим счетом, может быстро и легко подключить свой торговый счет к RealColibri',
        feature2:
            'Вся прибыль остается на вашем торговом счете. Мы взимаем комиссию за использование алгоритма в нашем утилитарном токене RCBC.',
        feature3:
            'Только вы управляете своим торговым счетом и работой алгоритма.',
        feature4:
            'Торговые параметры установлены для минимизации рисков для стабильной и безопасной торговли.',
        chooseExchange: 'Выберите вашу биржу',
        requirementsTitle: 'Требования для начала:',
        requirement1: 'Аккаунт RealColibri',
        requirement2: 'Кошелек TronLink',
        requirement3: 'Аккаунт на бирже',
        requirement4:
            'Минимальный депозит для начала автоматической торговли: 10,000 USDT',
        automateTitle: 'Как автоматизировать вашу торговлю:',
        automateStep1: 'Зарегистрируйтесь на RealColibri',
        automateStep2:
            'Откройте счет на бирже (<a href="https://www.binance.com/en/register?ref=39094470" target="_blank" rel="noreferrer" className={s.guideLink}>Binance</a>)',
        automateStep3:
            'Пополните торговый счет на бирже и переведите средства на фьючерсный кошелек',
        automateStep4:
            'Создайте API ключи в аккаунте на бирже и подключите их в аккаунте RealColibri',
        automateStep5:
            'Купите RCBC и пополните комиссионный депозит в аккаунте RealColibri',
        automateStep6:
            'На странице автоматической торговли RealColibri (перейдите в Торговля > Крипто > Binance в верхнем меню), выберите символ и нажмите старт',
        exchangeTitle: 'ОБМЕН TRX НА RCBC',
        exchangeStep1: 'Создайте кошелек TronLink',
        exchangeStep2: 'Переведите TRX на него',
        exchangeStep3: 'Обменяйте TRX на RCBC с помощью этой формы',
        exchangeStep4:
            'Пополните комиссионный депозит в вашем аккаунте RealColibri',
        exchangeInfo:
            'Обменяйте TRX на RCBC для оплаты комиссии за использование алгоритма.',
        refreshInfo:
            'Обновите страницу после установки. Вам будет предложено подключить ваш кошелек к сайту.',
        contactUs:
            'Свяжитесь с нами, если у вас есть вопросы или нужна дополнительная информация:',
        contactEmail: 'mail@realcolibri.com',
    },
    es: {
        cryptoMarkets: 'MERCADOS CRIPTO',
        ourFeatures: 'Nuestras características:',
        feature1:
            'Cualquier cliente de Binance, con una cuenta nueva o existente, puede conectar rápida y fácilmente su cuenta de trading a RealColibri',
        feature2:
            'Todas las ganancias permanecen en su cuenta de trading. Cobramos nuestra comisión por el uso del algoritmo en nuestro token utilitario RCBC.',
        feature3:
            'Solo usted controla su cuenta de trading y la operación del algoritmo.',
        feature4:
            'Los parámetros de trading están configurados para minimizar los riesgos para un trading estable y seguro.',
        chooseExchange: 'Elija su intercambio',
        requirementsTitle: 'Requisitos para empezar:',
        requirement1: 'Cuenta de RealColibri',
        requirement2: 'Cartera TronLink',
        requirement3: 'Cuenta en el intercambio',
        requirement4:
            'Depósito mínimo para empezar el trading automático: 10,000 USDT',
        automateTitle: 'Cómo automatizar su trading:',
        automateStep1: 'Regístrese en RealColibri',
        automateStep2:
            'Abra una cuenta en el intercambio (<a href="https://www.binance.com/en/register?ref=39094470" target="_blank" rel="noreferrer" className={s.guideLink}>Binance</a>)',
        automateStep3:
            'Fondee su cuenta de trading en el intercambio y transfiera fondos a la cartera de futuros',
        automateStep4:
            'Cree claves API en la cuenta del intercambio y conéctelas en la cuenta de RealColibri',
        automateStep5:
            'Compre RCBC y recargue el depósito de comisión en la cuenta de RealColibri',
        automateStep6:
            'En la página de trading automatizado de RealColibri (vaya a Comercio > Cripto > Binance en el menú superior), seleccione un símbolo y presione iniciar',
        exchangeTitle: 'CAMBIE TRX POR RCBC',
        exchangeStep1: 'Cree una cartera TronLink',
        exchangeStep2: 'Transfiera TRX a ella',
        exchangeStep3: 'Cambie TRX por RCBC usando este formulario',
        exchangeStep4:
            'Recargue el depósito de comisión en su cuenta de RealColibri',
        exchangeInfo:
            'Cambie TRX por RCBC para pagar una comisión por usar el algoritmo.',
        refreshInfo:
            'Actualice la página después de la instalación. Se le pedirá que conecte su cartera al sitio web.',
        contactUs:
            'No dude en contactarnos si tiene alguna pregunta o necesita información adicional:',
        contactEmail: 'mail@realcolibri.com',
    },
    fr: {
        cryptoMarkets: 'MARCHÉS CRYPTO',
        ourFeatures: 'Nos caractéristiques :',
        feature1:
            'Tout client Binance, avec un compte nouvellement ouvert ou existant, peut rapidement et facilement connecter son compte de trading à RealColibri',
        feature2:
            "Tous les profits restent sur votre compte de trading. Nous prélevons notre commission pour l'utilisation de l'algorithme dans notre token utilitaire RCBC.",
        feature3:
            "Vous seul contrôlez votre compte de trading et le fonctionnement de l'algorithme.",
        feature4:
            "Les paramètres de trading sont définis pour minimiser les risques afin d'assurer un trading stable et sécurisé.",
        chooseExchange: 'Choisissez votre bourse',
        requirementsTitle: 'Exigences pour commencer :',
        requirement1: 'Compte RealColibri',
        requirement2: 'Portefeuille TronLink',
        requirement3: 'Compte sur la bourse',
        requirement4:
            'Dépôt minimum pour démarrer le trading automatique : 10 000 USDT',
        automateTitle: 'Comment automatiser votre trading :',
        automateStep1: 'Inscrivez-vous sur RealColibri',
        automateStep2:
            'Ouvrez un compte sur la bourse (<a href="https://www.binance.com/en/register?ref=39094470" target="_blank" rel="noreferrer" className={s.guideLink}>Binance</a>)',
        automateStep3:
            'Alimentez votre compte de trading sur la bourse et transférez les fonds dans le portefeuille à terme',
        automateStep4:
            'Créez des clés API dans le compte sur la bourse et connectez-les au compte RealColibri',
        automateStep5:
            'Achetez du RCBC et alimentez le dépôt de commission dans le compte RealColibri',
        automateStep6:
            'Sur la page de trading automatisé de RealColibri (allez à Commerce > Crypto > Binance dans le menu supérieur), sélectionnez un symbole et appuyez sur démarrer',
        exchangeTitle: 'ÉCHANGEZ TRX CONTRE RCBC',
        exchangeStep1: 'Créez un portefeuille TronLink',
        exchangeStep2: 'Transférez TRX dessus',
        exchangeStep3: 'Échangez TRX contre RCBC en utilisant ce formulaire',
        exchangeStep4:
            'Alimentez le dépôt de commission dans votre compte RealColibri',
        exchangeInfo:
            "Échangez TRX contre RCBC pour payer une commission pour l'utilisation de l'algorithme.",
        refreshInfo:
            "Actualisez la page après l'installation. Vous serez invité à connecter votre portefeuille au site Web.",
        contactUs:
            "N'hésitez pas à nous contacter si vous avez des questions ou besoin d'informations supplémentaires :",
        contactEmail: 'mail@realcolibri.com',
    },
    de: {
        cryptoMarkets: 'KRYPTOMÄRKTE',
        ourFeatures: 'Unsere Merkmale:',
        feature1:
            'Jeder Binance-Kunde, mit einem neu eröffneten oder bestehenden Konto, kann sein Handelskonto schnell und einfach mit RealColibri verbinden',
        feature2:
            'Alle Gewinne verbleiben auf Ihrem Handelskonto. Wir erheben unsere Provision für die Nutzung des Algorithmus in unserem RCBC-Utility-Token.',
        feature3:
            'Nur Sie kontrollieren Ihr Handelskonto und den Betrieb des Algorithmus.',
        feature4:
            'Die Handelsparameter sind so eingestellt, dass Risiken für einen stabilen und sicheren Handel minimiert werden.',
        chooseExchange: 'Wählen Sie Ihre Börse',
        requirementsTitle: 'Voraussetzungen für den Einstieg:',
        requirement1: 'RealColibri-Konto',
        requirement2: 'TronLink-Wallet',
        requirement3: 'Konto auf der Börse',
        requirement4:
            'Mindestkapital für den Start des automatischen Handels: 10.000 USDT',
        automateTitle: 'So automatisieren Sie Ihren Handel:',
        automateStep1: 'Registrieren Sie sich bei RealColibri',
        automateStep2:
            'Eröffnen Sie ein Konto auf der Börse (<a href="https://www.binance.com/en/register?ref=39094470" target="_blank" rel="noreferrer" className={s.guideLink}>Binance</a>)',
        automateStep3:
            'Finanzieren Sie Ihr Handelskonto auf der Börse und übertragen Sie die Mittel in das Futures-Wallet',
        automateStep4:
            'Erstellen Sie API-Schlüssel im Konto auf der Börse und verbinden Sie diese im RealColibri-Konto',
        automateStep5:
            'Kaufen Sie RCBC und laden Sie den Kommissionsdeposit im RealColibri-Konto auf',
        automateStep6:
            'Auf der automatisierten Handelsseite von RealColibri (gehen Sie zu Handel > Krypto > Binance im oberen Menü), wählen Sie ein Symbol und drücken Sie Start',
        exchangeTitle: 'TAUSCHEN SIE TRX GEGEN RCBC',
        exchangeStep1: 'Erstellen Sie ein TronLink-Wallet',
        exchangeStep2: 'Überweisen Sie TRX darauf',
        exchangeStep3: 'Tauschen Sie TRX mit diesem Formular gegen RCBC',
        exchangeStep4:
            'Laden Sie den Kommissionsdeposit in Ihrem RealColibri-Konto auf',
        exchangeInfo:
            'Tauschen Sie TRX gegen RCBC, um eine Provision für die Nutzung des Algorithmus zu zahlen.',
        refreshInfo:
            'Aktualisieren Sie die Seite nach der Installation. Sie werden aufgefordert, Ihr Wallet mit der Website zu verbinden.',
        contactUs:
            'Kontaktieren Sie uns gerne, wenn Sie Fragen haben oder zusätzliche Informationen benötigen:',
        contactEmail: 'mail@realcolibri.com',
    },
    it: {
        cryptoMarkets: "MERCATI CRIPTOVALUTE",
        ourFeatures: "Le nostre caratteristiche:",
        feature1: "Qualsiasi cliente Binance, con un conto appena aperto o esistente, può collegare rapidamente e facilmente il proprio conto di trading a RealColibri.",
        feature2: "Tutti i profitti rimangono nel tuo conto di trading. Addebitiamo la nostra commissione per l'uso dell'algoritmo nel nostro token di utilità RCBC.",
        feature3: "Solo tu controlli il tuo conto di trading e il funzionamento dell'algoritmo.",
        feature4: "I parametri di trading sono impostati per ridurre al minimo i rischi per un trading stabile e sicuro.",
        chooseExchange: "Scegli il tuo exchange",
        requirementsTitle: "Requisiti per iniziare:",
        requirement1: "Account RealColibri",
        requirement2: "Portafoglio TronLink",
        requirement3: "Conto sull'exchange",
        requirement4: "Deposito minimo per avviare il trading automatico: 10.000 USDT",
        automateTitle: "Come automatizzare il tuo trading:",
        automateStep1: "Registrati su RealColibri",
        automateStep2: `Apri un conto sull'exchange (<a href="https://www.binance.com/en/register?ref=39094470" target="_blank" rel="noreferrer" className={s.guideLink}>Binance</a>)`,
        automateStep3: "Finanzia il tuo conto di trading sull'exchange e trasferisci fondi nel portafoglio futures",
        automateStep4: "Crea chiavi API nel conto sull'exchange e collegale nel conto RealColibri",
        automateStep5: "Acquista RCBC e ricarica il deposito commissioni nel conto RealColibri",
        automateStep6: "Nella pagina di trading automatico di RealColibri (vai su Trade > Crypto > Binance nel menu in alto), seleziona un simbolo e premi inizia",
        exchangeTitle: "CAMBIA TRX IN RCBC",
        exchangeStep1: "Crea un portafoglio TronLink",
        exchangeStep2: "Trasferisci TRX su di esso",
        exchangeStep3: "Scambia TRX per RCBC usando questo modulo",
        exchangeStep4: "Ricarica il deposito commissioni nel tuo account RealColibri",
        exchangeInfo: "Scambia TRX per RCBC per pagare una commissione per l'utilizzo dell'algoritmo.",
        refreshInfo: "Aggiorna la pagina dopo l'installazione. Ti verrà chiesto di collegare il tuo portafoglio al sito.",
        contactUs: "Non esitare a contattarci se hai domande o hai bisogno di ulteriori informazioni:",
        contactEmail: "mail@realcolibri.com",
    },    
};

export default translations
