import s from './Profile.module.scss'

import React, { useCallback, useContext, useEffect } from 'react'
import { Button, Form } from 'antd'
import { TronLink } from 'core/tron'
import { MAX_FEE_LIMIT, RCFACTORY_ADDRESS } from 'core/tron/tron.consts'
import { AccountContext, LoaderContext } from 'core/context'
import {
    BlockWrapper,
    ExchangeForm,
    InputControl,
    QuestionIconTip,
} from 'shared/components'
import { getTransactionInfo } from 'shared/utils'
import { AccountService } from 'api/http'
import { ZERO_ADDRESS } from 'shared/consts'
import { Deposit } from '../Deposit'

/** Создание сервисного контракта для действующего клиента */
export const Profile: React.FC = React.memo(() => {
    const [form] = Form.useForm()
    const { tronLinkAuth, accountData, tronAddresses, auth } =
        useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)

    const handleFinish = useCallback(
        async (values: { referrer: string }) => {
            try {
                setLoader(true)

                if (!accountData?.id || !TronLink.tronWeb) {
                    return
                }

                try {
                    const contract = await TronLink.instanceReferral
                        .mint(values.referrer || RCFACTORY_ADDRESS)
                        .send({
                            shouldPollResponse: true,
                            feeLimit: MAX_FEE_LIMIT,
                        })
                    await AccountService.update(accountData.id, {
                        tronAddress: TronLink.tronWeb.address.fromHex(contract),
                    })
                    await auth()
                } catch (e: any) {
                    await getTransactionInfo(e)
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [tronLinkAuth, setLoader, auth, accountData?.id]
    )

    useEffect(() => {
        if (!accountData?.referrerId || !tronAddresses.tron) return

        AccountService.getReferrerAddress(accountData.referrerId).then(
            ({ referrerAddress }) => {
                if (referrerAddress) {
                    TronLink.instanceReferral
                        .contracts(referrerAddress)
                        .call()
                        .then((service: string) => {
                            if (service === ZERO_ADDRESS) {
                                form.setFieldsValue({ referrerAddress })
                            }
                        })
                }
            }
        )
    }, [accountData?.referrerId, tronAddresses.tron, form])

    return (
        <div>
            <BlockWrapper className={s.wrapper}>
                <Form onFinish={handleFinish} form={form} className={s.text}>
                    <div style={{ fontSize: 16, marginBottom: 10 }}>
                        Connect to the stacking and referral system
                    </div>

                    <Form.Item
                        name="referrer"
                        rules={[
                            {
                                validator: (_, value) =>
                                    !value || TronLink.tronWeb?.isAddress(value)
                                        ? Promise.resolve()
                                        : Promise.reject(),
                                message: 'Incorrect address format',
                            },
                        ]}
                    >
                        <InputControl
                            placeholder="Referrer (optional field)"
                            suffix={
                                <QuestionIconTip title="Specify the tron address of the account of the person who invited you to our system" />
                            }
                        />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" size="large">
                        Get complete access
                    </Button>
                </Form>
            </BlockWrapper>

            <div className={s.exchange}>
                <div style={{ margin: '10px', textAlign: 'center' }}>
                    <p>
                        Exchange TRX for RCBC to pay a commission for using the
                        algorithm.
                    </p>
                </div>

                <ExchangeForm />

                {!TronLink.base58Address ? (
                    <div style={{ margin: '10px', textAlign: 'center' }}>
                        <p>
                            Refresh the page after installation. You will be
                            prompted to connect your wallet to the website.
                        </p>
                    </div>
                ) : null}
            </div>

            <div className={s.deposit}>
                <Deposit />
            </div>
        </div>
    )
})
