import {
    AreaChartOutlined,
    DollarOutlined,
    PercentageOutlined,
    TeamOutlined,
} from '@ant-design/icons'

interface InfoItem {
    text: string
    icon: React.ComponentType<any>
}

interface Translations {
    [key: string]: {
        title: string
        description: string
        button: string
        info: InfoItem[]
    }
}

export const translations: Translations = {
    en: {
        title: 'WHAT REALCOLIBRI IS',
        description:
            'Advanced trading automation system with a unique trading algorithm at its core',
        button: 'LEARN MORE',
        info: [
            {
                text: 'A stable and efficient automated trading algorithm for trading futures contracts on global and crypto markets',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin is an internal utility token used as a commission for using the algorithm and more',
                icon: DollarOutlined,
            },
            {
                text: 'A referral system, which allows to earn 50% of the market commission of attracted users',
                icon: TeamOutlined,
            },
            {
                text: 'Passive income from the staking system — cashback of 50% of the total market commission in proportion to the blocked share',
                icon: PercentageOutlined,
            },
        ],
    },
    es: {
        title: 'QUÉ ES REALCOLIBRI',
        description:
            'Sistema avanzado de automatización comercial con un algoritmo de trading único en su núcleo',
        button: 'APRENDER MÁS',
        info: [
            {
                text: 'Un algoritmo de trading automatizado estable y eficiente para operar contratos futuros en mercados globales y criptográficos',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin es un token de utilidad interno utilizado como comisión por el uso del algoritmo y más',
                icon: DollarOutlined,
            },
            {
                text: 'Un sistema de referidos que permite ganar el 50% de la comisión de mercado de los usuarios atraídos',
                icon: TeamOutlined,
            },
            {
                text: 'Ingresos pasivos del sistema de staking: cashback del 50% de la comisión total de mercado en proporción a la participación bloqueada',
                icon: PercentageOutlined,
            },
        ],
    },
    ru: {
        title: 'ЧТО ТАКОЕ REALCOLIBRI',
        description:
            'Современная система автоматизации торговли с уникальным торговым алгоритмом в основе',
        button: 'УЗНАТЬ БОЛЬШЕ',
        info: [
            {
                text: 'Стабильный и эффективный алгоритм автоматизированной торговли для торговли фьючерсами на мировых и криптовалютных рынках',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin — внутренний утилитарный токен, используемый в качестве комиссии за использование алгоритма и более',
                icon: DollarOutlined,
            },
            {
                text: 'Система реферралов, позволяющая зарабатывать 50% рыночной комиссии с привлеченных пользователей',
                icon: TeamOutlined,
            },
            {
                text: 'Пассивный доход от системы стейкинга — кэшбэк 50% от общей рыночной комиссии пропорционально заблокированной доле',
                icon: PercentageOutlined,
            },
        ],
    },
    hy: {
        title: 'ԻՆՉ Է REALCOLIBRI-Ը',
        description:
            'Առևտրի ավտոմատացման առաջադեմ համակարգ՝ եզակի առևտրային ալգորիթմով',
        button: 'ԻՄԱՆԱԼ ԱՎԵԼԻՆ',
        info: [
            {
                text: 'Կայուն և արդյունավետ ավտոմատացված առևտրային ալգորիթմ՝ ֆյուչերսային պայմանագրերի առևտրի համար համաշխարհային և կրիպտո շուկաներում',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin-ը ներքին օգտակար թոքեն է, որը օգտագործվում է որպես ալգորիթմի օգտագործման միջնորդավճար և ավելին',
                icon: DollarOutlined,
            },
            {
                text: 'Ռեֆերալային համակարգ, որը թույլ է տալիս ստանալ 50% շուկայական միջնորդավճարը ներգրավված օգտատերերից',
                icon: TeamOutlined,
            },
            {
                text: 'Պասիվ եկամուտ ստեյքինգ համակարգից՝ 50% շուկայական միջնորդավճարի վերադարձ՝ համամասնական բլոկավորված մասնաբաժնին',
                icon: PercentageOutlined,
            },
        ],
    },
    de: {
        title: 'WAS IST REALCOLIBRI',
        description:
            'Fortschrittliches Handelsautomatisierungssystem mit einem einzigartigen Handelsalgorithmus im Kern',
        button: 'MEHR ERFAHREN',
        info: [
            {
                text: 'Ein stabiles und effizientes automatisiertes Handelssystem für den Handel mit Terminkontrakten auf globalen und Krypto-Märkten',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin ist ein interner Utility-Token, der als Provision für die Nutzung des Algorithmus und mehr verwendet wird',
                icon: DollarOutlined,
            },
            {
                text: 'Ein Empfehlungsprogramm, das es ermöglicht, 50% der Marktprovision der angeworbenen Nutzer zu verdienen',
                icon: TeamOutlined,
            },
            {
                text: 'Passives Einkommen aus dem Staking-System — Cashback von 50% der gesamten Marktprovision im Verhältnis zum gesperrten Anteil',
                icon: PercentageOutlined,
            },
        ],
    },
    fr: {
        title: "QU'EST-CE QUE REALCOLIBRI",
        description:
            "Système avancé d'automatisation du trading avec un algorithme de trading unique au cœur",
        button: 'EN SAVOIR PLUS',
        info: [
            {
                text: 'Un algorithme de trading automatisé stable et efficace pour le trading de contrats à terme sur les marchés mondiaux et crypto',
                icon: AreaChartOutlined,
            },
            {
                text: "RealColibri Coin est un token utilitaire interne utilisé comme commission pour l'utilisation de l'algorithme et plus encore",
                icon: DollarOutlined,
            },
            {
                text: 'Un système de parrainage, qui permet de gagner 50% de la commission du marché des utilisateurs attirés',
                icon: TeamOutlined,
            },
            {
                text: 'Revenu passif du système de staking — cashback de 50% de la commission totale du marché en proportion de la part bloquée',
                icon: PercentageOutlined,
            },
        ],
    },
    it: {
        title: 'COS\'È REALCOLIBRI',
        description: 'Sistema avanzato di automazione del trading con un algoritmo di trading unico al centro',
        button: 'SCOPRI DI PIÙ',
        info: [
            {
                text: 'Un algoritmo di trading automatizzato stabile ed efficiente per il trading di contratti futures sui mercati globali e delle criptovalute',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin è un token di utilità interno utilizzato come commissione per l\'uso dell\'algoritmo e altro',
                icon: DollarOutlined,
            },
            {
                text: 'Un sistema di referral che consente di guadagnare il 50% della commissione di mercato degli utenti attratti',
                icon: TeamOutlined,
            },
            {
                text: 'Reddito passivo dal sistema di staking — cashback del 50% della commissione di mercato totale in proporzione alla quota bloccata',
                icon: PercentageOutlined,
            },
        ],
    },
};
