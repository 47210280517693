// src/pages/Main/Features/Features.tsx
import s from './Features.module.scss'
import React from 'react'
import { Col, Row } from 'antd'
import { useLanguage } from 'context/LanguageContext' // Assuming you have a language context for managing translations
import { translations } from './translations' // Import translations

const Features: React.FC = React.memo(() => {
    const { language } = useLanguage()
    const { header, features } = translations[language]

    return (
        <div className={s.wrapper}>
            <div className={s.container}>
                <h2 className={s.header}>{header}</h2>

                <Row gutter={20}>
                    {features.map(({ icon: Icon, text, title }, i) => (
                        <Col
                            className={s.feature}
                            key={i}
                            xs={24}
                            sm={12}
                            md={8}
                        >
                            <Icon className={s.icon} />

                            <h3 className={s.title}>{title}</h3>

                            <p className={s.text}>{text}</p>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
})

export default Features
