import React from 'react';
import { NavLink } from 'react-router-dom';
import { ADMIN_MENU_ITEM, MENU_ITEM } from './Header.consts';
import { TronAddressesProps } from 'core/context/Account/AccountContext.types';
import { NAV_LINK_NAMES } from 'shared/consts';
import { AccountModel } from 'api/http/models';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
export const getMenuList = (
    addresses: TronAddressesProps,
    accountData?: AccountModel
): ItemType[] => {
    const menuList: ItemType[] = [
        {
            label: 'Autotrading',
            key: 'autoTrading',
            children: [
                {
                    label: (
                        <NavLink to={NAV_LINK_NAMES.GLOBAL_MARKETS}>
                            Global markets
                        </NavLink>
                    ),
                    key: 'globalMarkets',
                },
                {
                    label: (
                        <NavLink to={NAV_LINK_NAMES.CRYPTO_MARKETS}>
                            Crypto markets
                        </NavLink>
                    ),
                    key: 'cryptoMarkets',
                },
            ],
        },
    ]

    if (accountData?.id) {
        menuList.push(...MENU_ITEM)
        if (accountData.isAdmin) {
            menuList.push(...ADMIN_MENU_ITEM)
        }
    }

    menuList.push({
        label: <NavLink to={NAV_LINK_NAMES.PREMIUM}>Premium</NavLink>,
        key: 'premium',
    })

    menuList.push({
        label: <NavLink to={NAV_LINK_NAMES.GUIDES}>Guides</NavLink>,
        key: 'guides',
    })

    if (addresses.service) {
        menuList.push({
            label: (
                <NavLink to={NAV_LINK_NAMES.ACCOUNT}>
                    {`${addresses.tron?.slice(0, 4)}...${addresses.tron?.slice(
                        -4
                    )}`}
                </NavLink>
            ),
            key: 'profile',
        })
    } else if (accountData?.id) {
        menuList.push({
            label: (
                <NavLink to={NAV_LINK_NAMES.ACCOUNT}>
                    {accountData.username}
                </NavLink>
            ),
            key: 'profile',
        })
    }

    return menuList
}