import React, { useState } from 'react'
import s from './Premium.module.scss'
import { BlockWrapper, PageContent } from 'shared/components'
import {
    QRCode,
    Cascader,
    Button,
    Drawer,
    Typography,
    Alert,
    Card,
    Collapse,
    Row,
    Col,
} from 'antd'
import { options, LinkPaths } from './Premium.utils'
import TableModule from './TableModule'
import { useLanguage } from 'context/LanguageContext'
import translations from './translations' // Correct import for translations

export const Premium: React.FC = React.memo(() => {
    const [selectedKeys, setSelectedKeys] = useState<(string | number)[]>([])
    const [selectedPath, setSelectedPath] = useState<string | null>(null)
    const [selectedValue, setSelectedValue] = useState<string | null>(null)
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)

    const { language } = useLanguage()
    const t = translations[language]

    const handleCascaderChange = (
        value: (string | number)[],
        selectedOptions?: any[]
    ) => {
        setSelectedKeys(value)
        if (value.length === 2) {
            const [path, selectedValue] = value
            setSelectedPath(path as string)
            setSelectedValue(selectedValue as string)
        } else {
            setSelectedPath(null)
            setSelectedValue(null)
        }
    }

    const { Panel } = Collapse

    const handleButtonClick = () => {
        if (selectedPath && selectedValue) {
            const link =
                LinkPaths[selectedPath as keyof typeof LinkPaths]?.[
                    selectedValue as keyof (typeof LinkPaths)[keyof typeof LinkPaths]
                ]
            if (link) {
                window.open(link, '_blank')
            }
        }
        setDrawerVisible(true)
    }

    const handleGuideClick = () => {
        window.location.href = '/guides#10'
    }

    const handleDrawerClose = () => {
        setDrawerVisible(false)
    }

    return (
        <PageContent>
            <div className={s.banner}>
                <div className={s.banner_img} />
                <div className={s.overlay} />
                <div className={s.title}>{t.BANNER_TITLE}</div>
            </div>

            <div className={s.wrapper}>
                <Row className={s.container} gutter={[20, 20]} align="middle">
                    <Col xs={24} lg={12}>
                        <h1 className={s.header} style={{ fontSize: 40 }}>
                            <b>{t.HEADER}</b>
                        </h1>
                        <div className={s.guideTitle} style={{ fontSize: 20 }}>
                            <h1>{t.NO_FEES}</h1>
                            <h1>{t.NO_LIMITS}</h1>
                            <h1>{t.SIMPLE}</h1>
                        </div>
                        <br />
                    </Col>

                    <Col xs={24} lg={12}>
                        <BlockWrapper className={s.guide}>
                            <h3 className={s.guideTitle}>
                                {t.SELECT_EXCHANGE_PACKAGE}
                            </h3>
                            <Cascader
                                options={options}
                                style={{ width: '60%' }}
                                placeholder={t.SELECT_EXCHANGE_PACKAGE}
                                size="large"
                                expandTrigger="hover"
                                className={s.cascader}
                                value={selectedKeys.map(String)}
                                onChange={handleCascaderChange}
                            />
                            <Button
                                className={s.guidebutton}
                                type="primary"
                                size="large"
                                onClick={() => setDrawerVisible(true)}
                                disabled={!selectedPath || !selectedValue}
                            >
                                {t.NEXT}
                            </Button>
                            <br />
                        </BlockWrapper>
                    </Col>
                </Row>
            </div>

            <Drawer
                title={t.PURCHASE}
                placement="bottom"
                closable={true}
                onClose={handleDrawerClose}
                visible={drawerVisible}
                height={800}
                mask={false}
                destroyOnClose={true}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <BlockWrapper className={s.guide}>
                    <Typography.Title level={4}>
                        {t.YOU_ARE_ABOUT_TO_PURCHASE}
                    </Typography.Title>
                    <Typography.Paragraph>
                        {selectedKeys.join(', ')}
                    </Typography.Paragraph>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <QRCode
                            value="TRqqcy2vovUatHxx6C9sw4FECgfNK7eLYw"
                            size={196}
                        />
                    </div>
                    <Typography.Paragraph
                        copyable={{
                            text: 'TRqqcy2vovUatHxx6C9sw4FECgfNK7eLYw',
                        }}
                    >
                        TRqqcy2vovUatHxx6C9sw4FECgfNK7eLYw
                    </Typography.Paragraph>
                    <div
                        className={s.text}
                        dangerouslySetInnerHTML={{
                            __html: t.PAYMENT_INSTRUCTIONS,
                        }}
                    />
                    <Alert message={t.ALERT} type="info" showIcon />
                    <ul className={s.textlist}>
                        <li>{t.CONTACT}</li>
                        <li>{t.REDIRECT}</li>
                    </ul>
                    <Button
                        className={s.guidebutton}
                        type="primary"
                        size="large"
                        onClick={handleButtonClick}
                    >
                        {t.PROCEED}
                    </Button>
                    <Card
                        className={s.card}
                        title={
                            <span style={{ fontSize: 20 }}>
                                {t.BINANCE_INSTRUCTIONS}
                            </span>
                        }
                        style={{ width: '100%' }}
                        headStyle={{
                            backgroundColor: '#e6f4ff',
                            color: 'black',
                        }}
                    >
                        <ol>
                            {t.BINANCE_STEPS.map(
                                (step: string, index: number) => (
                                    <li key={index}>{step}</li>
                                )
                            )}
                        </ol>
                    </Card>
                    <Card
                        className={s.card}
                        title={
                            <span style={{ fontSize: 20 }}>
                                {t.GLOBAL_MARKET_INSTRUCTIONS}
                            </span>
                        }
                        style={{ width: '100%' }}
                        headStyle={{
                            backgroundColor: '#e6f4ff',
                            color: 'black',
                        }}
                    >
                        <ol className={s.numberlist}>
                            {t.GLOBAL_MARKET_STEPS.map(
                                (step: string, index: number) => (
                                    <li key={index}>{step}</li>
                                )
                            )}
                        </ol>
                    </Card>
                </BlockWrapper>
                <BlockWrapper className={s.guide}>
                    <h3
                        style={{
                            marginTop: '0px',
                            width: '100%',
                            fontSize: '24px',
                        }}
                    >
                        {t.USE_GUIDES}
                    </h3>
                    <Button
                        className={s.guidebutton}
                        type="primary"
                        size="large"
                        onClick={handleGuideClick}
                    >
                        {t.GUIDES}
                    </Button>
                </BlockWrapper>
            </Drawer>

            <Card
                className={s.desc}
                title={
                    <h3 style={{ fontSize: '26px' }}>
                        {t.ABOUT_REALCOLIBRI_PREMIUM}
                    </h3>
                }
                headStyle={{ background: '#f0f5fa' }}
            >
                <p>{t.ABOUT_TEXT_1}</p>
                <br />
                <p>{t.ABOUT_TEXT_2}</p>
            </Card>

            <div className={s.table}>
                <TableModule />
                <br />
                <p style={{ fontSize: 12 }}>{t.TABLE_NOTE}</p>
            </div>

            <div
                className={s.guide}
                style={{
                    borderRadius: 20,
                    borderColor: '#dcdcdc',
                    borderWidth: 1,
                    borderStyle: 'solid',
                }}
            >
                <h1
                    className={s.header}
                    style={{ marginTop: 0, textAlign: 'center' }}
                >
                    {t.FAQ_TITLE}
                </h1>
                <Collapse
                    style={{
                        borderRadius: 5,
                        textAlign: 'left',
                        backgroundColor: '#f0f5fa',
                    }}
                    accordion
                >
                    {t.FAQ_QUESTIONS.map(
                        (
                            faq: { question: string; answer: string },
                            index: number
                        ) => (
                            <Panel header={faq.question} key={index.toString()}>
                                <p>{faq.answer}</p>
                            </Panel>
                        )
                    )}
                </Collapse>
            </div>

            <h3 className={s.mail}>
                <a href="mailto:mail@realcolibri.com">
                    <div>{t.FEEL_FREE_CONTACT}</div>
                    <div>{t.EMAIL}</div>
                </a>
            </h3>
        </PageContent>
    )
})
