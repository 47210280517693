import React from 'react'
import s from './GlobalMarkets.module.scss'
import { BlockWrapper, ExchangeForm, PageContent } from 'shared/components'
import { EXCHANGES, NAV_LINK_NAMES } from 'shared/consts'
import { Button, Col, Row } from 'antd'
import { TronLink } from '../../core/tron'
import { Join } from '../Main/Join/Join'
import translations from './translations' // Ensure this matches the filename case
import { useLanguage } from '../../context/LanguageContext' // Correct import path

export const GlobalMarkets: React.FC = React.memo(() => {
    const { language } = useLanguage()
    const t = translations[language]

    return (
        <PageContent>
            <div className={s.banner}>
                <div className={s.img} />
                <div className={s.overlay} />
                <div className={s.title}>{t.globalMarkets}</div>
            </div>

            <BlockWrapper className={s.guide}>
                <h3 className={s.guideTitle}>{t.ourFeatures}</h3>

                <ul>
                    <li>{t.feature1}</li>
                    <li dangerouslySetInnerHTML={{ __html: t.feature2 }}></li>
                    <li>{t.feature3}</li>
                    <li>{t.feature4}</li>
                    <li>{t.feature5}</li>
                    <li>{t.feature6}</li>
                </ul>
            </BlockWrapper>

            <h2 className={s.subTitle}>{t.chooseExchange}</h2>

            <BlockWrapper className={s.exchanges}>
                {EXCHANGES.global.map((el, i) => (
                    <a
                        href={el.href}
                        className={s.link}
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                    >
                        <img src={el.icon} alt={el.href} height={40} />
                    </a>
                ))}
            </BlockWrapper>

            <BlockWrapper className={s.guide}>
                <h3 className={s.guideTitle}>{t.requirementsTitle}</h3>

                <ul>
                    <li>{t.requirement1}</li>
                    <li>{t.requirement2}</li>
                    <li>{t.requirement3}</li>
                    <li>{t.requirement4}</li>
                    <li>{t.requirement6Mesu3}</li>
                    <li>{t.requirement6Esu3}</li>
                </ul>
            </BlockWrapper>

            <BlockWrapper className={s.guide}>
                <h3 className={s.guideTitle}>{t.automateTitle}</h3>

                <ol>
                    <li>{t.automateStep1}</li>
                    <li
                        dangerouslySetInnerHTML={{ __html: t.automateStep2 }}
                    ></li>
                    <li>{t.automateStep3}</li>
                    <li>{t.automateStep4}</li>
                    <li>{t.automateStep5}</li>
                    <li>{t.automateStep6}</li>
                </ol>
            </BlockWrapper>

            <Join />

            <BlockWrapper className={s.exchange}>
                <h3 className={s.exchangeTitle}>{t.exchangeTitle}</h3>

                <Row gutter={20} align="middle" className={s.exchangeContent}>
                    <Col xs={24} md={15}>
                        <ol>
                            <li>{t.exchangeStep1}</li>
                            <li>{t.exchangeStep2}</li>
                            <li>{t.exchangeStep3}</li>
                            <li>{t.exchangeStep4}</li>
                        </ol>

                        <div className={s.guidesButton}>
                            <Button
                                href={`${NAV_LINK_NAMES.GUIDES}/#4`}
                                type="primary"
                                className={s.toGuides}
                            >
                                DETAILED GUIDES
                            </Button>
                        </div>
                    </Col>

                    <Col xs={24} md={9}>
                        <div style={{ margin: '10px', textAlign: 'center' }}>
                            <p>{t.exchangeInfo}</p>
                        </div>

                        <ExchangeForm />

                        {!TronLink.base58Address ? (
                            <div
                                style={{ margin: '10px', textAlign: 'center' }}
                            >
                                <p>{t.refreshInfo}</p>
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </BlockWrapper>

            <h3 className={s.mail}>
                <a href="mailto:mail@realcolibri.com">
                    <div>{t.contactUs}</div>
                    <div>{t.contactEmail}</div>
                </a>
            </h3>
        </PageContent>
    )
})
