import React from 'react'
import { Col, Layout, Row, Space } from 'antd'
import Logo from 'shared/img/logo.svg'
import realcolibriText from 'shared/img/realcolibriText.svg'
import { Link, useLocation } from 'react-router-dom'
import { LINKS } from './Footer.consts'
import { NAV_LINK_NAMES, ROUTE_NAMES } from 'shared/consts'
import { Copyrights } from './Copyrights'
import TradingPlatformByRithmic from 'shared/img/TradinPlatformByRithmic-Green.png'
import PoweredByOmneIcon from 'shared/img/Powered-by-Omne-small-white.png'
import { useLanguage } from 'context/LanguageContext'
import { translations } from './translations'
import s from './Footer.module.scss'

const { Footer: FooterAntd } = Layout

/** Подвал */
export const Footer: React.FC = React.memo(() => {
    const location = useLocation()
    const { language } = useLanguage()

    return (
        <>
            {location.pathname.includes(NAV_LINK_NAMES.TRADING_RITHMIC) && (
                <Row>
                    <Col xs={24} sm={12}>
                        <div className={s.rithmic}>
                            <img
                                src={TradingPlatformByRithmic}
                                alt="Trading Platform by Rithmic"
                            />
                        </div>
                    </Col>

                    <Col xs={24} sm={12}>
                        <div className={s.rithmic}>
                            <img
                                src={PoweredByOmneIcon}
                                alt="Powered by OMNE"
                            />
                        </div>
                    </Col>
                </Row>
            )}

            <FooterAntd className={s.footer}>
                <Row
                    justify="space-around"
                    align="middle"
                    className={s.content}
                >
                    <Col xs={24} md={8}>
                        <Link to={NAV_LINK_NAMES.MAIN} className={s.logo}>
                            <img src={Logo} alt="Logo" />
                            <img
                                src={realcolibriText}
                                style={{ width: '50%', height: '50%' }}
                                alt="Text"
                            />
                        </Link>
                    </Col>

                    <Col xs={24} md={8} className={s.info}>
                        <Space align="center">
                            <Link to={NAV_LINK_NAMES.TERMS}>
                                {translations[language].terms}
                            </Link>
                        </Space>

                        <div>
                            &#169; Copyright 2021-{new Date().getFullYear()}
                            &nbsp;RealColibri.com. All rights reserved.
                        </div>
                    </Col>

                    <Col xs={24} md={8} className={s.email}>
                        <a href="mailto:mail@realcolibri.com">
                            mail@realcolibri.com
                        </a>

                        <div>
                            {LINKS.map((el, i) => (
                                <a
                                    href={el.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={s.icon}
                                    key={i}
                                >
                                    {el.icon}
                                </a>
                            ))}
                        </div>
                    </Col>
                </Row>

                {location.pathname.includes(ROUTE_NAMES.RITHMIC) && (
                    <Copyrights />
                )}

                <div className={s.disclaimer}>
                    {translations[language].disclaimer}
                </div>
            </FooterAntd>
        </>
    )
})
