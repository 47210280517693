import React, { useContext, useEffect, useRef, useState } from 'react'
import { Carousel, Col, Row } from 'antd'
import { GetStarted } from '../GetStarted'
import { AccountContext } from 'core/context'
import { VideoIFrame } from '../../../shared/components'
import { getTranslation } from './translations'
import { useLanguage } from 'context/LanguageContext'
import s from './Banner.module.scss'

/** Блок с банером для главной страницы */
export const Banner: React.FC = React.memo(() => {
    const { accountData } = useContext(AccountContext)
    const { language } = useLanguage()
    const { bannerTitle, bannerText, videoLinks } = getTranslation(language)

    const carouselRef = useRef<React.ElementRef<typeof Carousel>>(null)
    const autoScrollRef = useRef<NodeJS.Timeout | null>(null)
    const restartTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const isInteracted = useRef(false)

    // State to store current and previous slide indices
    const [currentSlide, setCurrentSlide] = useState(0)
    const [prevSlide, setPrevSlide] = useState<number | null>(null)

    const PrevArrow = (props: any) => (
        <div className={s.prevArrow} onClick={props.onClick}>
            🡸
        </div>
    )

    const NextArrow = (props: any) => (
        <div className={s.nextArrow} onClick={props.onClick}>
            🡺
        </div>
    )

    const startAutoScroll = () => {
        if (!isInteracted.current) {
            autoScrollRef.current = setInterval(() => {
                if (carouselRef.current) {
                    carouselRef.current.next()
                }
            }, 60000) // now 10 seconds
        }
    }

    const stopAutoScroll = () => {
        if (autoScrollRef.current) {
            clearInterval(autoScrollRef.current)
            autoScrollRef.current = null
        }
    }

    const restartAutoScroll = () => {
        if (restartTimeoutRef.current) {
            clearTimeout(restartTimeoutRef.current)
        }
        restartTimeoutRef.current = setTimeout(() => {
            isInteracted.current = false
            startAutoScroll()
        }, 180000) // 180 seconds delay to restart auto-scroll
    }

    useEffect(() => {
        startAutoScroll()
        return () => {
            stopAutoScroll()
            if (restartTimeoutRef.current) {
                clearTimeout(restartTimeoutRef.current)
            }
        }
    }, [])

    const handleUserInteraction = () => {
        isInteracted.current = true
        stopAutoScroll()
        restartAutoScroll() // Start the timeout to restart auto-scroll after 30s
    }

    // Function to handle carousel change
    const handleCarouselChange = (currentIndex: number) => {
        setPrevSlide(currentSlide) // Store current slide as previous
        setCurrentSlide(currentIndex) // Update current slide index
    }

    return (
        <div className={s.wrapper}>
            <Row
                className={`${s.container} ${s['align-middle']}`}
                gutter={[20, 20]}
                align="middle"
            >
                <Col xs={24} lg={12}>
                    <h1 className={s.title}>{bannerTitle}</h1>
                    <div className={s.text}>{bannerText}</div>
                    {!accountData?.id && <GetStarted />}
                </Col>
                <Col xs={24} lg={12}>
                    <div
                        className={s.carouselWrapper}
                        onMouseEnter={handleUserInteraction}
                        onMouseLeave={restartAutoScroll}
                        onFocus={handleUserInteraction}
                        onBlur={restartAutoScroll}
                    >
                        <Carousel
                            arrows
                            prevArrow={<PrevArrow />}
                            nextArrow={<NextArrow />}
                            className={s.carousel}
                            ref={carouselRef}
                            afterChange={handleCarouselChange} // Listen to carousel changes
                        >
                            {videoLinks.map((link, index) => (
                                <div className={s.carouselItem} key={index}>
                                    {/* Reload video only if it was the previous slide */}
                                    <VideoIFrame
                                        className={s.video}
                                        src={link}
                                        key={
                                            prevSlide === index
                                                ? link
                                                : undefined
                                        } // Reload the previous video by changing the key
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </Col>
            </Row>
        </div>
    )
})
