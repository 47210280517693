import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Drawer, Grid, Layout, Menu, Select } from 'antd'
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons'
import { AccountContext } from 'core/context'
import { AuthService } from 'api/http'
import { NAV_LINK_NAMES } from 'shared/consts'
import { useElementVisible } from 'shared/hooks'
import { GuardAuthButtons } from './GuardAuthButtons'
import { getContainer } from 'shared/utils'
import { useLanguage } from 'context/LanguageContext'
import { getMenuList } from './Header.utils'
import cn from 'classnames'
import s from './Header.module.scss'
import Logo from 'shared/img/logo.svg'
import realcolibriText from 'shared/img/realcolibriText.svg'

const { Header: HeaderAntd } = Layout
const { Option } = Select

const languages = {
    en: '🇺🇸', // Default Language
    es: '🇪🇸',
    ru: '🇷🇺',
    hy: '🇦🇲',
    de: '🇩🇪',
    fr: '🇫🇷', 
    it: '🇮🇹', 
};

/** Site Header */
export const Header: React.FC = React.memo(() => {
    const { accountData, tronAddresses } = useContext(AccountContext)
    const { elementVisible, handleShowElement, handleHideElement } =
        useElementVisible()
    const isMobileMenu = !Grid.useBreakpoint().lg
    const { language, setLanguage } = useLanguage()

    const handleLanguageChange = (value: string) => {
        setLanguage(value as 'en' | 'es' | 'ru' | 'hy' | 'de' | 'fr' | 'it'); 
    };

    const handleLogout = async () => {
        try {
            handleHideElement()
            await AuthService.logout()
            window.location.replace(NAV_LINK_NAMES.MAIN)
        } catch (e) {
            console.log(e)
        }
    }

    const getHeaderMenu = (className?: string) => (
        <>
            <Menu
                mode={isMobileMenu ? 'inline' : 'horizontal'}
                className={cn(!isMobileMenu && s.desktopMenu)}
                rootClassName={cn(!isMobileMenu && s.desktopSubMenu)}
                onClick={handleHideElement}
                items={[
                    ...getMenuList(tronAddresses, accountData),
                    {
                        key: 'language',
                        label: (
                            <Select
                                value={language}
                                onChange={handleLanguageChange}
                                className={s.languageSelector}
                                onClick={e => e.stopPropagation()} // Prevent menu from closing
                            >
                                {Object.entries(languages).map(
                                    ([key, label]) => (
                                        <Option key={key} value={key}>
                                            {label}
                                        </Option>
                                    )
                                )}
                            </Select>
                        ),
                    },
                ]}
            />

            {accountData?.id ? (
                <Button
                    onClick={handleLogout}
                    icon={<LogoutOutlined />}
                    className={cn(!isMobileMenu && s.logout)}
                >
                    {language === 'en'
                        ? 'Sign out'
                        : language === 'es'
                        ? 'Cerrar sesión'
                        : language === 'ru'
                        ? 'Выйти'
                        : language === 'hy'
                        ? 'Դուրս գալ'
                        : language === 'de'
                        ? 'Abmelden' // German translation
                        : language === 'fr'
                        ? 'Se déconnecter' // French translation
                        : language === 'it'
                        ? 'Esci' // Italian translation
                        : ''}
                </Button>
            ) : (
                <GuardAuthButtons
                    className={className}
                    onCloseMenu={handleHideElement}
                />
            )}
        </>
    )

    return (
        <HeaderAntd className={s.wrapper}>
            <Link to={NAV_LINK_NAMES.MAIN} className={s.logo}>
                <img src={Logo} alt="Logo" />
                <img
                    src={realcolibriText}
                    style={{ width: '50%', height: '50%' }}
                    alt="Text"
                />
            </Link>

            {isMobileMenu ? (
                <>
                    <Button
                        type="link"
                        onClick={handleShowElement}
                        icon={<MenuOutlined />}
                        className={s.menuButton}
                    />

                    <Drawer
                        title="Menu"
                        placement="right"
                        onClose={handleHideElement}
                        open={elementVisible}
                        rootClassName={s.drawer}
                        getContainer={getContainer()}
                    >
                        {getHeaderMenu()}
                    </Drawer>
                </>
            ) : (
                getHeaderMenu(s.desktop)
            )}
        </HeaderAntd>
    )
})
