import React from 'react'
import { PageContent } from 'shared/components/PageContent'
import { Link } from 'react-router-dom'
import { NAV_LINK_NAMES } from 'shared/consts'

const ERROR_PAGE_CONTENT: Record<
    string,
    { title: string; description: string }
> = {
    '403': {
        title: 'Access error',
        description:
            'You do not have sufficient rights to view this page. Contact technical support',
    },
    '404': {
        title: 'Nothing was found for your query',
        description: '',
    },
    default: {
        title: 'An error has occurred',
        description: '',
    },
}

interface ErrorPageProps {
    errorCode: '403' | '404' | string
}

export const ErrorPage: React.FC<ErrorPageProps> = React.memo(
    ({ errorCode }) => {
        const currentError =
            ERROR_PAGE_CONTENT[errorCode] || ERROR_PAGE_CONTENT['default']

        return (
            <PageContent>
                <h1>{currentError.title}</h1>

                <main>
                    {currentError.description && (
                        <h2>{currentError.description}</h2>
                    )}

                    <div>
                        You can go to the&nbsp;
                        <Link to={NAV_LINK_NAMES.MAIN}>main page</Link>
                    </div>
                </main>
            </PageContent>
        )
    }
)
