import s from './LoginForm.module.scss'
import React, { useCallback, useContext, useState } from 'react'
import { Button, Divider, Form } from 'antd'
import {
    InputControl,
    InputPasswordControl,
} from 'shared/components/InputControl'
import { AuthService } from 'api/http'
import Logo from 'shared/img/logo.svg'
import { AccountContext, LoaderContext } from 'core/context'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import { LoginModel } from 'api/http/models'
import { TronLink } from 'core/tron'
import ReCAPTCHA from 'react-google-recaptcha'
import cn from 'classnames'
import translations from 'shared/components/LoginForm/translations'
import { useLanguage } from 'context/LanguageContext'

export const LoginForm: React.FC<PopupAdapterFormProps> = React.memo(
    ({ onRequestFinish, id }) => {
        const { auth, tronLinkAuth } = useContext(AccountContext)
        const { setLoader, isLoader } = useContext(LoaderContext)
        const [captchaError, setCaptchaError] = useState(false)
        const [captcha, setCaptcha] = useState<string | null>()

        const { language } = useLanguage() // Accessing the current language from the context
        const t = translations[language] // Get the translation object for the current language

        const handleFinish = useCallback(
            async (values: LoginModel) => {
                try {
                    if (!captcha) {
                        setCaptchaError(true)
                        return
                    }
                    setLoader(true)

                    await AuthService.login(values)
                    await auth()

                    onRequestFinish?.()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [auth, onRequestFinish, setLoader, captcha]
        )

        return (
            <Form<LoginModel>
                id={id}
                onFinish={handleFinish}
                validateMessages={{ required: t.REQUIRED_FIELD }}
                className={s.wrapper}
                size="large"
            >
                <img src={Logo} alt="Logo" className={s.img} />

                <h3 className={s.title}>{t.ACCOUNT_LOGIN}</h3>

                <Form.Item name="username" rules={[{ required: true }]}>
                    <InputControl placeholder={t.USERNAME_PLACEHOLDER} />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true }]}>
                    <InputPasswordControl
                        placeholder={t.PASSWORD_PLACEHOLDER}
                    />
                </Form.Item>

                {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                    <div className={cn(captchaError && s.captcha)}>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={setCaptcha}
                        />
                    </div>
                )}

                <Button
                    type="primary"
                    htmlType="submit"
                    block
                    className={s.button}
                >
                    {t.LOGIN}
                </Button>

                {!isLoader && (
                    <>
                        <Divider>{t.OR}</Divider>

                        {TronLink.base58Address ? (
                            <div>
                                <Button
                                    onClick={tronLinkAuth}
                                    className={s.button}
                                    type="primary"
                                >
                                    {t.LOGIN_WITH_TRONLINK}
                                </Button>
                                <p>{t.TRONLINK_PROMPT}</p>
                            </div>
                        ) : (
                            <div>
                                <p>{t.TRONLINK_REFRESH_PROMPT}</p>
                            </div>
                        )}
                    </>
                )}
            </Form>
        )
    }
)
