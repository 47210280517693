import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row } from 'antd' // Import Select from antd
import { BlockWrapper, PageContent, TableAdapter } from 'shared/components'
import { BinanceDealsService, BinanceService } from 'api/http'
import {
    DealsRequestModel,
    DealsResponseModel,
    MarketDataStatusResponseModel,
} from 'api/http/models'
import { AccountContext, LoaderContext } from 'core/context'
import {
    DEAL_TABLE_COLUMNS,
    QUERY_PARAMS_INITIAL_VALUES,
} from './Binance.consts'
import { StatisticProps } from './Binance.types'
import { getTableTitle } from './Binance.utils'
import { useFetch, useQueryParams } from 'shared/hooks'
import { AutoTrade } from './AutoTrade'
import { BinanceKeys } from './BinanceKeys'
import { BinanceStatistic } from './BinanceStatistic'
import translations, { Translations } from './translations'
import { useLanguage } from 'context/LanguageContext'

import s from './Binance.module.scss'

/** Торговля робота на Binance */
export const Binance: React.FC = React.memo(() => {
    const { accountData } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)
    const [getMarketData, marketData] = useFetch(BinanceService.get)
    const [robotData, setRobotData] = useState<MarketDataStatusResponseModel>()
    const [tableData, setTableData] = useState<DealsResponseModel[]>()
    const [itemsCount, setItemsCount] = useState<number>(0)
    const [statistic, setStatistic] = useState<StatisticProps>()
    const { language } = useLanguage() // use language context

    const { paginationOptions, queryParams, setQueryParams } =
        useQueryParams<DealsRequestModel>(QUERY_PARAMS_INITIAL_VALUES)

    /** Запрос статистики профита */
    const fetchProfitStatistic = useCallback(async () => {
        try {
            setLoader(true)

            let periodStatisticData
            const { body } = queryParams
            if (body.start_date) {
                periodStatisticData =
                    await BinanceDealsService.getPeriodStatistic({
                        ...(body as DealsRequestModel),
                    })
            }

            setStatistic({
                ...(await BinanceDealsService.getStatistic()),
                amount: periodStatisticData?.amount,
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader, queryParams])

    /**
     * Запрос данных таблицы
     */
    const fetchTableDate = useCallback(async () => {
        try {
            setLoader(true)

            const { body, limit, skip } = queryParams

            const { items, total } = await BinanceDealsService.search(
                body as DealsRequestModel,
                skip,
                limit
            )

            setTableData(items)
            setItemsCount(total)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [queryParams, setLoader])

    useEffect(() => {
        if (!marketData?.id || robotData) return

        const fetch = async () => {
            try {
                setLoader(true)

                setRobotData(await BinanceService.getStatus(marketData.id))
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }

        fetch()
    }, [marketData?.id, setLoader, robotData])

    useEffect(() => {
        if (accountData?.id) {
            getMarketData(accountData.id)
        }
        fetchTableDate()
        fetchProfitStatistic()
    }, [accountData?.id, fetchTableDate, fetchProfitStatistic, getMarketData])

    const t: Translations = translations[language]

    return (
        <PageContent>
            <BlockWrapper className={s.notification}>
                <span className={s.importantText}>
                    <b>{t.important}</b>
                </span>{' '}
                <span dangerouslySetInnerHTML={{ __html: t.notification }} />
            </BlockWrapper>

            <Row gutter={15}>
                <Col xs={24} md={16} xl={9}>
                    <BlockWrapper className={s.card}>
                        <h2 className="mb-10">{t.algorithmControl}</h2>

                        <div>{t.optimalDepositSize}</div>

                        <AutoTrade
                            robotData={robotData}
                            onRobotData={setRobotData}
                            marketId={marketData?.id}
                        />
                    </BlockWrapper>
                </Col>

                <Col xs={24} md={8} xl={5}>
                    <BinanceKeys className={s.card} />
                </Col>

                <Col xs={24} md={24} xl={10}>
                    <BinanceStatistic
                        className={s.card}
                        statistic={statistic}
                    />
                </Col>
            </Row>

            <TableAdapter
                title={getTableTitle(setQueryParams)}
                columns={DEAL_TABLE_COLUMNS}
                dataSource={tableData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    )
})
